import find from 'lodash/find';
/**
 * Рекурсивный поиск модели контрола
 * @param state {Object<[]>} - модель формы
 * @param id {String} - id контрола
 * @param parentGroupId {String} - id родителя контрола
 * @returns {Object<[]>}
 */
const findFieldValue = (state, id, parentGroupId) => {
  let field;
  if (state[id]) {
    field = state[id];
  }
  if (parentGroupId) {
    const addedGroupsEl = find(state, 'addedGroups');
    if (addedGroupsEl) {
      const groupEl = addedGroupsEl.addedGroups.find((group) => group.id === parentGroupId);
      field = groupEl.value[id];
    }
  } else {
    Object.keys(state).forEach((key) => {
      if (state[key] && typeof state[key] === 'object' && key !== 'addedGroups' && key !== 'value') {
        const found = findFieldValue(state[key], id);
        if (found) field = found;
      }
    });
  }
  return field;
};

const checkValue = (v, vInState) => {
  if (!vInState) return false;
  if (Array.isArray(vInState)) {
    return vInState.includes(String(v));
  }
  return String(v) === String(vInState);
};

const checkVisible = (initVisible, state, groupId, groupLinks, parentGroupId) => {
  let visible = initVisible;
  if (!state && typeof state !== 'object') return visible;
  if (groupLinks && groupLinks.length) {
    const group = groupLinks.find((el) => String(el.id) === String(groupId));
    if (group) {
      visible = group.watch.some((field) => {
        const valueInState = findFieldValue(state, field.id, parentGroupId);
        return field.values.some((v) => checkValue(v, valueInState));
      });
    }
  }
  return visible;
};

export default checkVisible;
